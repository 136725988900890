export default class Settings {
  private settings: any = {
    apiHost: 'https://api.hashtags.pl', //'https://dev.api.hashtags.pl', //'https://api.hashtags.pl',
    storagePath: 'https://api.hashtags.pl/storage/', //'https://api.hashtags.pl/storage/'
  };
  constructor() {
    const host = window.location.host;
    if (host.startsWith('localhost')) {
      this.settings.apiHost = 'https://api.hashtags.pl'; // 'https://dev.api.hashtags.pl'; //'https://api.hashtags.pl'; //;
      this.settings.storagePath = 'https://api.hashtags.pl/storage/';
    }
    if (host.startsWith('widget.hashtags.pl')) {
      this.settings.apiHost = 'https://api.hashtags.pl'; //'https://api.hashtags.pl';
      this.settings.storagePath = 'https://api.hashtags.pl/storage/'; //'https://api.hashtags.pl/storage/';
    }
  }
  
  getSetting(name: string) {
    return this.settings[name];
  }
  
  setSetting(name: string, value: any) {
    this.settings[name] = value;
  }
}
